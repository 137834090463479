/*===================================================================*/
/*=== Very important to use <Link to> rather than <a> in the menu ===*/
/*=== If <a> is used, then the props in the next route disappear! ===*/
/*===================================================================*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pageStyle } from '../../config/paths';

// some of the antd css properties are overridden
import './headerCss.css'

class Header extends Component {
  _isMounted = false;

  //**********************************************************/
  // this executes only once, the first time that the app loads
  componentDidMount() {
    this._isMounted = true;
  }
 
  //**********************************************************/
  componentWillUnmount() {
    this._isMounted = false;
  }
 
  //**********************************************************/
  renderContent() {

    const pageContainerStyle = {
      height: 60,
      width: '100%',
      backgroundColor: '#2E4553',
      margin: 0,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const headerContainerStyle = {
      height: 60,
      width: '100%',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: 18,
      color: '#FFFFFF',
      backgroundColor: '#2E4553',
      display: 'flex',
      flexDirection: 'row',
      margin: 0,
      padding: 0
    };

    return (
      <div style={pageContainerStyle}>
      <div style={pageStyle} >
        <div className="header" style={headerContainerStyle}>
        </div>
      </div>
      </div>
    );
  }

  //**********************************************************/
  render() {

    return (
      this.renderContent()
    );
  }

}

//**********************************************************/
function mapStateToProps(state) {
  return {
    webPage: state.webPage
  };  
}

//**********************************************************/
// this should return a plain object
const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
