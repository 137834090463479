import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Offline, Online } from "react-detect-offline";
import queryString from 'query-string';
import { http_prefix_mojo} from '../config/paths';
import { dbSetPart, dbGetCategoriesByPart, dbGetDefaults, dbGetParts, dbGetCustomer, dbSetCustomerMsg } from '../actions';

import LandingPage from './landingPage/LandingPage';
import CategoriesPage from './categoriesPage/CategoriesPage';
import ChaptersPage from './chaptersPage/ChaptersPage';
import LessonsPage from './lessonsPage/LessonsPage';
import VideosPage from './videosPage/VideosPage';

import { pageStyle} from '../config/paths';

let moment = require('moment');

class App extends Component {

  constructor(props) {
    super(props);
    this.state = { isDataFetched: false, validAccount: false };

    // load googe font
    var WebFont = require('webfontloader');
    WebFont.load({
      google: {
        families: ['Lato']
      }
    });
    
  }

  async readUrl() {
    //Example --  http://localhost:3000/?uid=1&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcnNfaWQiOjEsImN1c3RvbWVyIjoiRmxpcExlYXJuIn0.sBu4tyI9bLOrlrul94Cuekxy7OQDXG12PxRWWK0Rho4
    const str = window.location.href.replace(http_prefix_mojo+ '/','');
    const query = queryString.parse(str);   

console.log('str,query',str,query);    

    let customers_id = 0;
    let token = '';
    let errorMsg = '';

    // No parameters passed in URL
    if (Object.keys(query).length === 0) {
      errorMsg = 'URL parameters "uid" & "token" not specified';
    }

    // Capture UID in url
    if (errorMsg === '') {
      if (!('uid' in query)) {
        errorMsg = 'URL parameter "uid" not specified';
      } else {
        let customers_q_id = isNaN(query.uid) ? -1 : query.uid;
        customers_id = ((typeof customers_q_id === 'string') && !(isNaN(customers_q_id))) ? parseInt(customers_q_id) : -1;
       if (customers_id === -1) {
        errorMsg = 'Not a valid uid';
       }

      }  
    }

    // Capture TOKEN in url
    if (errorMsg === '') {
      if (!('token' in query)) {
        errorMsg = 'URL parameter "token" not specified';
      } else {
        token = query.token;
      }        
    }

    // Pass dummy TOKEN if blank
    token = (token.trim() === '') ? '@#$' : token;
    await this.props.dbGetCustomer_action(customers_id, token);

    if ((this.props.dbCustomers.customers_id < 0) && (errorMsg === '')) {
      errorMsg = 'Not a valid uid / token combination';
    }

    // Check against account expiry date for the customer
    let today = moment(new Date()).format("YYYY-MM-DD");

    let active = (typeof this.props.dbCustomers !== undefined) && (this.props.dbCustomers.expiresOn !== null) &&
                 (this.props.dbCustomers.expiresOn >= today);
    if ((! active) && (errorMsg === '')) {
      errorMsg = 'Your accout has expired';
    }
            
    await this.props.dbSetCustomerMsg_action(errorMsg);

    await this.props.dbGetDefaults_action();   
    await this.props.dbSetPart_action(1);
    await this.props.dbGetParts_action();
    await this.props.dbGetCategoriesByPart_action(1);

    const isAccountValid = (this.props.dbCustomers.customers_id > 0) && active;
    await this.setState({isDataFetched: true, validAccount: isAccountValid});  

  }

  // this executes only once, the first time that the app loads
  componentDidMount() {   
    this.readUrl();
  }


  defaultWebPage = () => {
    return (<Route path={"/"} component={CategoriesPage} />);
  }

  render () {

    // Data not yet fetched
    if (! this.state.isDataFetched)
      return (
        <div></div>
      )

    const offlineStyle = {
      height: 40,
      width: '100%',
      backgroundColor: 'red',
      color: '#FFFFFF',
      fontWeight: '600',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    };

    // Not a valid account (incorrect url)
    if (! this.state.validAccount) {
      return (
        <div>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <div>
              <Switch>
                <Route path="/" component={LandingPage} />
              </Switch>
            </div>
          </BrowserRouter>
        </div>
      )
    }

    return (
      <div>
        <Online>
        <div>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <div>
            <Switch>
              <Route path="/Categories" component={CategoriesPage} />
              <Route path="/Chapters" component={ChaptersPage} />
              <Route path="/Lessons" component={LessonsPage} />
              <Route path="/Videos" component={VideosPage} />
              {this.defaultWebPage()}
            </Switch>
          </div>
        </BrowserRouter>
        </div>
        </Online>
        <Offline>
          <div style={pageStyle}>
            <div style={offlineStyle} > You are offline </div>
          </div>
        </Offline>
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    dbCustomers: state.dbCustomers
 };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dbSetPart_action: async (parts_id) => {
      await dispatch(dbSetPart(parts_id))
    },
    dbGetCategoriesByPart_action: async (parts_id) => {
      await dispatch(dbGetCategoriesByPart(parts_id))
    },
    dbGetDefaults_action: async () => {
      await dispatch(dbGetDefaults())
    },
    dbGetParts_action: async () => {
      await dispatch(dbGetParts())
    },
    dbSetCustomerMsg_action: async (errorMsg) => {
      await dispatch(dbSetCustomerMsg(errorMsg))
    },
    dbGetCustomer_action: async (customers_id, token) => {
      await dispatch(dbGetCustomer(customers_id, token));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
