/*================================================================*/
/*=== Keep track of the webpage index in the store ===============*/
/*=== This way components can be manipulated based on the page ===*/
/*================================================================*/

import { DB_GET_DEFAULTS } from '../actions/types';

export default function(state = {defaults: []}, action) {

  switch (action.type) {

    case DB_GET_DEFAULTS:
      return {defaults: action.payload};

    default:
      return state;
  }

}
