import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Skeleton } from 'antd';
import { withRouter } from 'react-router-dom';
import { dbSetLesson, setDefaultLessonPage } from '../../actions';
import { lessonPhpPath, pageStyle } from '../../config/paths';

// some of the antd css properties are overridden
import './lessonsFramePageCss.css'

class Lessons extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { isDataFetched: null, lessonsPerPage: 10, showTutorial: 0 };
  }

  // get all categories for selected parts_id from the reducer
  componentDidMount() {
    this._isMounted = true;

    this.fetchInitialData();
  }

  //**********************************************************/
  fetchInitialData = async() => {
    const currentLesson = this.props.dbLessons.lessons.filter(obj => { return obj.lessons_id === this.props.dbLessons.lessons_id});
    await this.props.setDefaultLessonPage_action(Math.ceil(currentLesson[0].srNo/this.state.lessonsPerPage));   
    if (this._isMounted) {
      this.setState({isDataFetched: true});   
    }
  }

  //**********************************************************/
  componentWillUnmount() {
    this._isMounted = false;
  }
   
  // get lesson sr no
  getLessonSrNoString = () => {
    if (this.props.dbLessons.lessons.length > 0) {
      const lessonRec = this.props.dbLessons.lessons.filter(obj => { return obj.lessons_id === this.props.dbLessons.lessons_id});
      if (lessonRec.length > 0) {
        return "Lesson " + lessonRec[0].srNo + " of " + this.props.dbLessons.lessons.length;
      }
    }
    return "";
  }

  // get next lesson
  getNextLesson = async () => {
    if (this.props.dbLessons.lessons.length > 0) {
      const currentLesson = this.props.dbLessons.lessons.filter(obj => { return obj.lessons_id === this.props.dbLessons.lessons_id});
      const higherLessons = this.props.dbLessons.lessons.filter(obj => { return obj.srNo > currentLesson[0].srNo});
      if (higherLessons.length > 0) {
        const nextLessons_id = higherLessons[0].lessons_id;
        await this.props.dbSetLesson_action(nextLessons_id);
        await this.props.setDefaultLessonPage_action(Math.ceil(higherLessons[0].srNo/this.state.lessonsPerPage));   
      }
    }
    return;
  }

  // get previous lesson
  getPrevLesson = async () => {
    if (this.props.dbLessons.lessons.length > 0) {
      const currentLesson = this.props.dbLessons.lessons.filter(obj => { return obj.lessons_id === this.props.dbLessons.lessons_id});
      const lowerLessons = this.props.dbLessons.lessons.filter(obj => { return obj.srNo < currentLesson[0].srNo});
      if (lowerLessons.length > 0) {
        const prevLessons_id = lowerLessons[lowerLessons.length-1].lessons_id;
        await this.props.dbSetLesson_action(prevLessons_id);
        await this.props.setDefaultLessonPage_action(Math.ceil(lowerLessons[lowerLessons.length-1].srNo/this.state.lessonsPerPage));   
      }
    }
    return;
  }

  // get lesson path
  getLessonPath = () => {
    var parts_id = this.props.dbParts.parts_id;

    if ((this.props.dbChapters.chapters.length > 0) && (this.props.dbLessons.lessons.length > 0)) {
      const chapterRec = this.props.dbChapters.chapters.filter(obj => { return obj.chapters_id === this.props.dbChapters.chapters_id});
      const lessonRec = this.props.dbLessons.lessons.filter(obj => { return obj.lessons_id === this.props.dbLessons.lessons_id});
      if (lessonRec.length > 0) {
        var lessonPath = lessonPhpPath + parts_id + '/Chapters/' + chapterRec[0].chapterDir + '/' + lessonRec[0].lessonCode + '.php';
        return lessonPath;
      }
    }

    return '';

  }


  // get lesson code
  getLessonCode = () => {

    if (this.props.dbLessons.lessons.length > 0) {
      const lessonRec = this.props.dbLessons.lessons.filter(obj => { return obj.lessons_id === this.props.dbLessons.lessons_id});
      if (lessonRec.length > 0) {
        const lessonCode = lessonRec[0].lessonCode;
        return lessonCode;
      }
    }

    return '';

  }


  // is first lesson
  isFirstLesson = () => {

    if (this.props.dbLessons.lessons.length > 0) {
      const lessonRec = this.props.dbLessons.lessons.filter(obj => { return obj.lessons_id === this.props.dbLessons.lessons_id});
      if (lessonRec.length > 0) {
        if (this.props.dbLessons.lessons[0].srNo === lessonRec[0].srNo) {
          return true;
        }
      }
    }
    return false;
  }

  // is last lesson
  isLastLesson = () => {

    if (this.props.dbLessons.lessons.length > 0) {
      const lessonRec = this.props.dbLessons.lessons.filter(obj => { return obj.lessons_id === this.props.dbLessons.lessons_id});
      var num_lessons = this.props.dbLessons.lessons.length;
      if ((this.props.dbLessons.lessons.length > 0) && (lessonRec.length > 0)) {
        if (this.props.dbLessons.lessons[num_lessons-1].srNo === lessonRec[0].srNo) {
          return true;
        }
      }
    }
    return false;
  }

  renderPrevNextButtonsLayout = (type) => {

    const buttonContainerStyle = {
      height: '100%',

      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      //alignItems: (type === 1) ? 'flex-start' : 'flex-end',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const prevButtonStyle = {
      height: 30,
      backgroundColor: 'rgba(46,69,83,1)',
      color: '#FFFFFF'
    };

    const nextButtonStyle = {
      height: 30,
      backgroundColor: 'rgba(46,69,83,1)',
      color: '#FFFFFF'
    };

    if (type === 1) {
      if (this.isFirstLesson()) {
        return (<div style={buttonContainerStyle} ></div>);
      } else {
        return (<div style={buttonContainerStyle} > <Button style={prevButtonStyle}  id="prevLessonButton"  onClick={() => this.getPrevLesson()}> <Icon type="left" style={{ marginLeft: 0 }} />Previous Lesson</Button> </div>);
      }
    } else if (type === 2) {
      if (this.isLastLesson()) {
        return (<div style={buttonContainerStyle} ></div>);
      } else {
        return (<div style={buttonContainerStyle}> <Button style={nextButtonStyle} id="nextLessonButton" onClick={() => this.getNextLesson()}>Next Lesson<Icon type="right" /></Button> </div>);
      }
    }
  }


  // render the content
  renderContent() {

    const pageContainerStyle = {
      height: 480,
      width: '100%',
      color: '#000000',
      margin: 0,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const lessonContainerStyle = {
      height: 480,
      width: '100%',
      minWidth: 1000,
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: 18,
      color: '#000000',
      boxSizing: 'border-box',
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const animationContainerStyle = {
      height: '100%',
      width: '100%',
      margin: 0,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const canvasContainerStyle = {
      height: 440,
      width: '100%',
      margin: 0,
      overflow: 'hidden'
    };

    const iFrameStyle = {
      height: 540,
      width: '100%',
      overflow: "hidden",
      marginLeft: this.props.dbDefaults.defaults[0].iframeMarginLeft,
      marginTop: this.props.dbDefaults.defaults[0].iframeMarginTop,
      //marginLeft: 0,
      //marginTop: -100,
      border: 'none'
    };

    const buttonsContainerStyle = {
      height: 40,
      width: '100%',
      minWidth: 750,
      margin: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const buttonsStyle = {
      height: '100%',
      flex: 7,
      minWidth: 750,
      maxWidth: 750,
      //width: (window.innerWidth >= 1100 ) ? 750 : '100%',
      margin: 0,
      backgroundColor: 'rgba(46,69,83,1)',
      color: '#FFFFFF',
      fontWeight: '600px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const lessonCommentStyle = {
      height: '100%',
      fontSize: 16,
      flex: 3,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const blankContainerStyle = {
      height: '100%',
      flex: 3
    };

    const blankStyle = {
    };

/*
<iframe title="1" src={this.getLessonPath()} scrolling="no" style={iFrameStyle}></iframe>
*/
    return (
      <div style={pageContainerStyle}>
      <div style={pageStyle}>
      <div className="main_container" style={lessonContainerStyle} >
      <Skeleton loading={!this.state.isDataFetched} active paragraph={{ rows: 10 }}>  

          <div style={animationContainerStyle} className="container_animation">

            <div key={this.getLessonCode()} style={canvasContainerStyle} className="animation_canvas">
              <iframe title="1" src={this.getLessonPath()} scrolling="no" style={iFrameStyle}></iframe>
            </div>

            <div style={buttonsContainerStyle} className="animation_lesson_buttons_container">
              <div style={buttonsStyle} className="animation_lesson_buttons">
                {this.renderPrevNextButtonsLayout (1)}
                <div style={lessonCommentStyle}> {this.getLessonSrNoString()} </div>
                {this.renderPrevNextButtonsLayout (2)}
              </div>
              <div style={blankContainerStyle} className="animation_lesson_blank">
                <div style={blankStyle}> </div>
              </div>
            </div>

          </div>

      </Skeleton>  
      </div>
      </div>
      </div>
    );
  }

  render() {
    return (
      this.renderContent()
    );
  }

}

const mapStateToProps = (state) => {
  return {
    dbParts: state.dbParts,
    dbCategories: state.dbCategories,
    dbChapters: state.dbChapters,
    dbLessons: state.dbLessons,
    dbDefaults: state.dbDefaults,
    defaultLessonPage: state.defaultLessonPage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dbSetLesson_action: async (lessons_id) => {
        await dispatch(dbSetLesson(lessons_id))
    },
    setDefaultLessonPage_action: async(pageNo) => {
      await dispatch(setDefaultLessonPage(pageNo))
    }   

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Lessons));
