import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pageStyle } from '../../config/paths';

var moment = require('moment');

class ErrorBanner extends React.Component {
  _isMounted = false;

  //**********************************************************/
  componentDidMount() {
    this._isMounted = true;

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //**********************************************************/
  formatDate = (x) => {
    return moment(x).format('ll');
  }

  //**********************************************************/
  renderTextWarningContent = () => {

    const textWarningContainerStyle = {
      height: '100%',
      flex: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const textWarningStyle = {
      fontSize: 16,
      color: '#4F4F4F',
    };

    const text = this.props.message;

    if (text.trim() === '') {
      return(<div></div>);
    }

    return (
      <div style={textWarningContainerStyle}>
        <div style={textWarningStyle}>{text}</div>
      </div>
    );
  }


  //**********************************************************/
  renderContent = () => {

    const pageContainerStyle = {
      width: '100%',
      backgroundColor: '#FFF5D3',
      margin: 0,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const textContainerStyle = {
      width: '100%',
      height: 50,
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'row'
    };

    return (
       <div style={pageContainerStyle} >
        <div style={pageStyle}>
          <div style={textContainerStyle}>
            {this.renderTextWarningContent()}
          </div>
        </div>
       </div>
     );
  }

  //**********************************************************/
  render() {

    return (
      this.renderContent()
    );
  }

}

//**********************************************************/
const mapStateToProps = (state) => {
  return {
    dbUserPayments: state.dbUserPayments,
    dbParts: state.dbParts,
    dbDefaults: state.dbDefaults
  };
};


export default connect(mapStateToProps)(withRouter(ErrorBanner));
