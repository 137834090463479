import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pageStyle } from '../../config/paths';

class Intro extends Component {

  // set web page
  componentDidMount() {
  }

  renderContent() {

    const pageContainerStyle = {
      height: (window.innerWidth < 1000) ? 800 : 400,
      width: '100%',
      backgroundColor: '#1CBEC1',
      margin: 0,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const introContainerStyle = {
      height: (window.innerWidth < 1000) ? 800 : 400,
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '18px',
      color: '#FFFFFF',
      backgroundColor: '#1CBEC1',
      display: 'flex',
      /*flexDirection: 'row',*/
      flexDirection: (window.innerWidth < 1000) ? 'column' : 'row',
      alignItems: 'center',
      justifyContent: 'center',
      paddingRight: '5%',
      paddingLeft: '5%'
    };

    const introLeftContainerStyle = {
      height: (window.innerWidth < 1000) ? 800 : 400,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingRight: '2%'
    };

    const introTitleContainerStyle = {
      height: 200,
      fontWeight: '600',
      fontSize: (window.innerWidth < 1000) ? 36 : 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const introTitleStyle = {
      // vertically center the text in the container with the 3 lines
    };

    const introSubtitleContainerStyle = {
      height: 200,
      fontWeight: '500',
      fontSize: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const introSubtitleStyle = {
    };

    const introAvatarContainerStyle = {
      height: (window.innerWidth < 1000) ? 400 : 400,
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: '2%'
    };

    const introAvatarStyle = {
      width: '100%',                             // width of container
      height: introAvatarContainerStyle.height*0.9,    // 10% of container height
      borderRadius: 20                         // rounded corners of image
    };

    return (
      <div style={pageContainerStyle}>
      <div style={pageStyle} >

        <div className="intro" style={introContainerStyle}>

          <div className="intro_left_container" style={introLeftContainerStyle} >
            <div style={introTitleContainerStyle}>
              <div style={introTitleStyle}>
                A new, fun way to learn Physics
              </div>
            </div>
            <div style={introSubtitleContainerStyle}>
              <div style={introSubtitleStyle}>
                  This software brings to you an all new way of learning
                  Physics concepts through animation & interaction.
                  The lessons guide you using a step by step approach ,
                  designed to bring concepts to life and make the whole
                  experience of learning more enjoyable.
              </div>
            </div>
          </div>

          <div className="intro_img" style={introAvatarContainerStyle} >
            <img style={introAvatarStyle} src={"landingPage/intro.png"} alt={"Intro Img"} />
          </div>

        </div>

      </div>
      </div>
    );
  }

  render() {
    return (
      this.renderContent()
    );
  }

}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps)(withRouter(Intro));
