import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from '../common/Header';
import BreadCrumb from '../common/BreadCrumb';
import BlankPanel from '../common/BlankPanel';
import LessonsFrame from '../lessonsPage/LessonsFrame';
import { setWebPage } from '../../actions';
import { WEBPAGE_LESSONS } from '../../actions/types';

class LessonsPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { isDataFetched: null};
  }

  // set web page
  componentDidMount() {
    this._isMounted = true;

    this.fetchInitialData();
   
  }

  //**********************************************************/
  fetchInitialData = async() => {
    await this.props.setWebPage_action(WEBPAGE_LESSONS);
    if (this._isMounted)
      this.setState({isDataFetched: true});   
  }

  //**********************************************************/
  componentWillUnmount() {
    this._isMounted = false;
  }

  //**********************************************************/
  renderContent() {
    return (
      <div>
        <Header/>
        <BreadCrumb/>
        <BlankPanel/>
        <LessonsFrame/>
      </div>
    );
  }

  //**********************************************************/
  render() {

    if (!this._isMounted) {
      return (
        <div></div>
      )
    }

    return (
      this.renderContent()
    );
  }

}

const mapStateToProps = (state) => {
  return {
    webPage: state.webPage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setWebPage_action: async (webPages_id) => {
        await dispatch(setWebPage(webPages_id))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LessonsPage));
