/*================================================================*/
/*=== Keep track of the webpage index in the store ===============*/
/*=== This way components can be manipulated based on the page ===*/
/*================================================================*/

import { DB_GET_VIDEOS } from '../actions/types';

export default function(state = {videos: []}, action) {

  switch (action.type) {

    case DB_GET_VIDEOS:
      return {videos: action.payload};

    default:
      return state;
  }

}
