/*================================================================*/
/*=== Keep track of the webpage index in the store ===============*/
/*=== This way components can be manipulated based on the page ===*/
/*================================================================*/

import { GET_LOGIN_ATTEMPT, SET_LOGIN_ATTEMPT, INCR_LOGIN_ATTEMPT } from '../actions/types';

export default function(state = {loginAttempt: 1}, action) {

  switch (action.type) {

    case GET_LOGIN_ATTEMPT:
      return state;

    case SET_LOGIN_ATTEMPT:
      return {loginAttempt: action.payload};

    case INCR_LOGIN_ATTEMPT:
      return {loginAttempt: state.loginAttempt+1};

    default:
      return state;
  }

}
