import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pageStyle } from '../../config/paths';

class BlankPanel extends Component {

  componentDidMount() {
  }

  renderBlankPanelLayout() {

    const blankPanelStyle = {
      height: 10,
      fontFamily: 'Lato',
      fontStyle: 'normal',
      color: '#000000',
      backgroundColor: 'rgba(242,242,242,1)'
    };

   return( <div style={blankPanelStyle} >
           </div>
      );

  }

  renderContent() {

    return (
      <div style={pageStyle}>

        {this.renderBlankPanelLayout()}

      </div>
    );
  }

  render() {
    return (
      this.renderContent()
    );
  }

}

export default connect()(withRouter(BlankPanel));
