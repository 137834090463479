export const FETCH_COMPANY_DATA = 'fetch_company_data';
export const DB_FETCH_CREATE_GOOGLE_USER = 'fetch_create_google_user';
export const SET_GOOGLE_USER = 'set_google_user';

export const DB_GET_USER = 'db_get_user';
export const DB_GET_USERS = 'db_get_users';
export const DB_SET_USER = 'db_set_user';
export const DB_RESET_USER = 'db_reset_user';

export const DB_GET_PART = 'db_get_part';
export const DB_GET_PARTS = 'db_get_parts';
export const DB_SET_PART = 'db_set_part';

export const DB_GET_CATEGORIES_BY_PART = 'db_get_categories_by_part';
export const DB_GET_CATEGORIES = 'db_get_categories';
export const DB_GET_CATEGORY = 'db_get_category';
export const DB_SET_CATEGORY = 'db_set_category';

export const DB_GET_CHAPTERS_BY_CATEGORY = 'db_get_chapters_by_category';
export const DB_GET_CHAPTERS = 'db_get_chapters';
export const DB_GET_CHAPTER = 'db_get_chapter';
export const DB_SET_CHAPTER = 'db_set_chapter';

export const DB_GET_LESSONS_BY_CATEGORY = 'db_get_lessons_by_category';
export const DB_GET_LESSONS_BY_CHAPTER = 'db_get_lessons_by_chapter';
export const DB_GET_LESSONS = 'db_get_lessons';
export const DB_GET_LESSON = 'db_get_lesson';
export const DB_SET_LESSON = 'db_set_lesson';

export const SET_WEBPAGE = 'set_webpage';
export const GET_WEBPAGE = 'get_webpage';
export const WEBPAGE_LANDING = 1;
export const WEBPAGE_CATEGORIES = 2;
export const WEBPAGE_CHAPTERS = 3;
export const WEBPAGE_LESSONS = 4;
export const WEBPAGE_PROFILES = 5;
export const WEBPAGE_SUBSCRIPTION = 6;
export const WEBPAGE_PYMT_SUCCESS = 7;
export const WEBPAGE_PYMT_FAILURE = 8;
export const WEBPAGE_SUBSCRIPTION_HISTORY = 9;
export const WEBPAGE_PRIVACY_POLICY = 10;
export const WEBPAGE_ABOUT_US = 11;
export const WEBPAGE_WHY_US = 12;
export const WEBPAGE_PYMT = 13;
export const WEBPAGE_VIDEOS = 14;

export const DB_UPDATE_USER = 'db_update_user';

export const DB_GET_USERTYPES = 'db_get_userTypes';

export const DB_INSERT_USER_PAYMENTS = 'db_insert_user_payments';
export const DB_GET_USER_PAYMENTS = 'db_get_user_payments';

export const DB_GET_PART_COSTS = 'db_get_part_costs';

export const DB_GET_DEFAULTS = 'db_get_defaults';

export const DB_GET_SUBSCRIPTION_HISTORY = 'db_get_subscription_history';
export const DB_GET_SUBSCRIPTION = 'db_get_subscription';
export const DB_UPDATE_GOOGLE_PICTURE = 'db_update_google_picture';

export const DB_EMAIL_INVOICE = 'db_email_invoice';

export const API_POST_PAYMENTS_MOJO = 'api_post_payments_mojo';

export const SET_PERMISSION = 'set_permission';
export const GET_PERMISSION = 'get_permission';

export const SET_DEFAULT_LESSON_PAGE = 'set_default_lesson_page';
export const GET_DEFAULT_LESSON_PAGE = 'get_default_lesson_page';

export const INCR_LOGIN_ATTEMPT = 'incr_login_attempt';
export const GET_LOGIN_ATTEMPT = 'get_login_attempt';
export const SET_LOGIN_ATTEMPT = 'set_login_attempt';

export const DB_GET_VIDEOS = 'db_get_videos';

export const DB_GET_CUSTOMER = 'db_get_customer';
export const DB_SET_CUSTOMER_MSG = 'db_set_customer_msg';
export const DB_RESET_CUSTOMER = 'db_reset_customer';
