/*================================================================*/
/*=== action.payload is returned as 'res' from the backend ... ===*/
/*=== ... this is returned as an object ... ======================*/
/*=== ... so this is converted into an array of objects ... ======*/
/*=== ... so that libraries like lodash can use it effectively ===*/
/*================================================================*/

import { DB_GET_CHAPTERS_BY_CATEGORY, DB_SET_CHAPTER } from '../actions/types';

export default function(state = {chapters_id: 1, chapters: []}, action) {

  switch (action.type) {
    case DB_GET_CHAPTERS_BY_CATEGORY:
      return {chapters_id: state.chapters.id, chapters: action.payload};

    case DB_SET_CHAPTER:
      return {...state, chapters_id: action.payload};

    default:
      return state;
  }

}
