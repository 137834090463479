import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List, Table, Icon, message, Skeleton } from 'antd';
import { dbSetChapter, dbGetLessonsByChapter, dbSetLesson, 
         setDefaultLessonPage, getDefaultLessonPage } from '../../actions';
import { pageStyle, HEADER_HEIGHT, BREADCRUMB_HEIGHT, FOOTER_HEIGHT } from '../../config/paths';

// some of the antd css properties are overridden
import './chaptersPageCss.css'

class Chapters extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { isChapterDataFetched: null, isLessonDataFetched: null, open: false, 
         defaultPage: (this.props.defaultLessonPage && this.props.defaultLessonPage.pageNo) ? this.props.defaultLessonPage.pageNo : 1};
  }


  // get all categories for selected parts_id from the reducer
  componentDidMount() {
    this._isMounted = true;

    this.fetchInitialData();
  }

  //**********************************************************/
  fetchInitialData = async() => {
    await this.onChapterSelect(this.props.dbChapters.chapters_id, 1);
    await this.props.getDefaultLessonPage_action(); 
    if (this._isMounted) {
      this.setState({isChapterDataFetched: true, defaultPage: this.props.defaultLessonPage.pageNo});   
    }
  }

  //**********************************************************/
  componentWillUnmount() {
    this._isMounted = false;
  }


  // onClick of the ListItem (Chapters)
  onChapterSelect = async (chapters_id, flag) => {

    this.setState({isLessonDataFetched: false});   

    await this.props.dbSetChapter_action(chapters_id);
    await this.props.dbGetLessonsByChapter_action(chapters_id);

    // simulate a click on paginator, so that you move to the 1st page
    var elem = document.getElementsByClassName("ant-pagination-item-1");
    if (elem.length > 0) {
      elem[0].click();
    }

    // if this comes from a click on the Chapters list, force lesson page to 1 ...
    // ... else grab it from whatever it was changed to in the Lessons form
    if (flag === 2) {
      await this.props.setDefaultLessonPage_action(1); 
      this.setState({defaultPage: 1});   
    }

    this.setState({isLessonDataFetched: true});   

  }

  onLessonSelect = async (lessons_id, srNo) => {
    if (window.innerWidth < 1000) {
      message.info('Please download the app to view the animation');
    } else {
      await this.props.dbSetLesson_action(lessons_id);

      //since we have include withRouter, we can do the following
      // didn't manage to render the table such that ... if not subscribed...
      // ... can click on only upto 10 lessons using <Link>
      await this.props.history.push({
        pathname: '/Lessons',
        state: {auth: true}
      });

    }
  }

  // get the name of the Chapter
  getChapterName = () => {
    if (this.props.dbChapters.chapters.length > 0) {
      const chapterRec = this.props.dbChapters.chapters.filter(obj => { return obj.chapters_id === this.props.dbChapters.chapters_id});
      return chapterRec[0].chapter;
    }
    return "";
  }

  // get number of lessons in the chapter
  getLessonCount = () => {
    const len = this.props.dbLessons.lessons.length;
    return len.toString();
  }

  // get number of lessons in the chapter
  getLessonCountString = () => {
    return (<span> Lessons in {this.getChapterName()} &nbsp; &nbsp;
            <span style={{color: 'rgba(0,0,0,0.5)'}}>
               (...{this.getLessonCount()})
            </span>
            </span>
    )
  }



  displayLessons = () => {

    const tableContainerStyle = {
      //height: '100%',
      boxSizing: 'border-box',
      margin: 0,
      flex: 2,
      width: '100%'
      //minWidth: 600
    };

    const tableContentsContainerStyle = {
      height: 520,
      width: '100%',
      top: 0,
      paddingTop: '2%',
      paddingRight: '3%',
      boxSizing: 'border-box',
      margin: 0
    };


    const tableColumns = [{
      title: 'No.',
      dataIndex: 'srNo',
      key: 'srNo',
      width: '10%',
      render: srNo => <div style={{fontSize: '16px', color: 'rgba(0,0,0,0.9)'}}><div className="tableItemLink"> {srNo} </div></div>

    }, {
      title: this.getLessonCountString,
      dataIndex: 'lesson',
      key: 'lessons_id',
      width: '75%',
      render: lesson => <div style={{fontSize: '16px', color: 'rgba(0,0,0,1)'}}><div className="tableItemLink"> {lesson} </div></div>
    }, {
      title: 'Practice',
      dataIndex: 'hasPractice',
      key: 'hasPractice',
      width: '15%',
      align: 'center',
      render: (text,record) => <div style={{fontSize: '16px', color: 'rgba(0,0,0,0.9)'}}><div className="tableItemLink"> {record.hasPractice===1 ? <Icon type="check-circle" theme="twoTone" twoToneColor="#0066ff" /> :''} </div></div>
    }];

    return (
      <div className="lessons" style={tableContainerStyle}>
        <div style={tableContentsContainerStyle}>
          <Skeleton loading={!this.state.isLessonDataFetched} active paragraph={{ rows: 10 }}>

          <Table
            columns={tableColumns}
            dataSource={this.props.dbLessons.lessons}
            size="small"
            rowKey="srNo"
            id="lessons_table"
            style={{ width: '100%' }}
            pagination={{ defaultCurrent: this.state.defaultPage }}

            onRow={(record) => {
              return {
                onClick: async () => {await this.onLessonSelect(record.lessons_id, record.srNo)},       // click row
                //onClick: async () => {this.openModal()}
              };
            }}
          />

          </Skeleton>
        </div>
      </div>
    );
  }

  // render the content
  renderContent = () => {

    // this is to force footer at the bottom in case of less content
    const categoryHeight = document.body.clientHeight - HEADER_HEIGHT - BREADCRUMB_HEIGHT - FOOTER_HEIGHT;

    const pageContainerStyle = {
      width: '100%',
      minHeight: categoryHeight,
      backgroundColor: '#FFFFFF',
      margin: 0,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const contentContainerStyle = {
      //height: 550,
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: 18,
      color: '#000000',
      backgroundColor: '#FFFFFF',
      margin: 0,
      display: 'flex',
      flexDirection: (window.innerWidth < 1000) ? 'column' : 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexWrap: 'wrap'
    };

    const listContainerStyle = {
      //height: 550,
      boxSizing: 'border-box',
      margin: 0,
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      minWidth: 300
    };


    const listHeaderStyle = {
      fontSize: 20,
      color: 'rgba(0,0,0,1)',
      backgroundColor: 'rgba(242,242,242,0.2)',
    };

    const listContentsContainerStyle = {
      //height: 480,
      fontSize: 18,
      fontWeight: '600',
      color: 'rgba(0, 0, 0, 0.6)',
      backgroundColor: '#FFFFFF',
      paddingRight: '20%',
      paddingLeft: '10%',
      paddingTop: '1%',
      boxSizing: 'border-box',
      margin: '0',
      alignItems: 'flex-start'
    };

    return (
      <div style={pageContainerStyle}>
      <div style={pageStyle}>

        <div className="content_container" style={contentContainerStyle}>

          <div className="chapters_content" style={listContainerStyle}>
          <Skeleton loading={!this.state.isChapterDataFetched} active paragraph={{ rows: 5 }}>
            <List
              header={<div style={listHeaderStyle}>Chapters</div>}
              dataSource={this.props.dbChapters.chapters}
              renderItem={
                item => (<List.Item><div style={{color: (this.props.dbChapters.chapters_id===item.chapters_id ? 'rgba(24,144,255,1)' : 'rgba(0,0,0,0.8)')}}
                         onClick={async () => await this.onChapterSelect(item.chapters_id,2)}>
                {item.chapter}  </div>  </List.Item>)
              }
              style={listContentsContainerStyle}
            />
          </Skeleton>
          </div>

          {this.displayLessons()}

        </div>

      </div>
      </div>
    )

  }

  render() {
    return (
      this.renderContent()
    );
  }

}

const mapStateToProps = (state) => {
  return {
    dbChapters: state.dbChapters,
    dbLessons: state.dbLessons,
    defaultLessonPage: state.defaultLessonPage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dbSetChapter_action: async (chapters_id) => {
        await dispatch(dbSetChapter(chapters_id))
    },
    dbGetLessonsByChapter_action: async (chapters_id) => {
      await dispatch(dbGetLessonsByChapter(chapters_id))
    },
    dbSetLesson_action: async (lessons_id) => {
        await dispatch(dbSetLesson(lessons_id))
    },
    setDefaultLessonPage_action: async () => {
      await dispatch(setDefaultLessonPage())
    },
    getDefaultLessonPage_action: async () => {
      await dispatch(getDefaultLessonPage())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Chapters));
