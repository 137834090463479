/*================================================================*/
/*=== action.payload is returned as 'res' from the backend ... ===*/
/*=== ... this is returned as an object ... ======================*/
/*=== ... so this is converted into an array of objects ... ======*/
/*=== ... so that libraries like lodash can use it effectively ===*/
/*================================================================*/

import { DB_GET_CATEGORIES_BY_PART, DB_GET_CATEGORIES, DB_SET_CATEGORY } from '../actions/types';

export default function(state = {categories_id: 1, categories: [], allCategories: []}, action) {

  switch (action.type) {
    case DB_GET_CATEGORIES_BY_PART:
      return {...state, categories_id: state.categories_id, categories: action.payload};

    case DB_GET_CATEGORIES:
      return {...state, allCategories: action.payload};

    case DB_SET_CATEGORY:
      return {...state, categories_id: action.payload};

    default:
      return state;
  }

}
