import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from '../common/Header';
import BreadCrumb from '../common/BreadCrumb';
import Chapters from './Chapters';

import { setWebPage } from '../../actions';
import { WEBPAGE_CHAPTERS } from '../../actions/types';

class ChaptersPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { isDataFetched: null};
  }

  // set web page
  componentDidMount() {
    this._isMounted = true;

    this.fetchInitialData();
   
    // if user enters route, state is undefined
    if ((this.props.location.state === undefined) || (this.props.dbCustomers.customers_id === undefined) ||
        (this.props.dbCustomers.customers_id < 0)) {
      this.props.history.push('/');  
    }

  }

  //**********************************************************/
  fetchInitialData = async() => {
    await this.props.setWebPage_action(WEBPAGE_CHAPTERS);
    if (this._isMounted)
      this.setState({isDataFetched: true});   
  }

  //**********************************************************/
  componentWillUnmount() {
    this._isMounted = false;
  }
 
  //**********************************************************/
  renderContent = () => {
    return (
      <div>
        <Header/>
        <BreadCrumb/>
        <Chapters/>
      </div>
    );
  }

  //**********************************************************/
  render() {

    if (!this._isMounted) {
      return (
        <div></div>
      )
    }

    return (
      this.renderContent()
    );
  }

}

const mapStateToProps = (state) => {
  return {
    webPage: state.webPage,
    dbCustomers: state.dbCustomers
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setWebPage_action: async (webPages_id) => {
        await dispatch(setWebPage(webPages_id))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChaptersPage));
