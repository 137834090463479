/*================================================================*/
/*=== action.payload is returned as 'res' from the backend ... ===*/
/*=== ... this is returned as an object ... ======================*/
/*=== ... so this is converted into an array of objects ... ======*/
/*=== ... so that libraries like lodash can use it effectively ===*/
/*================================================================*/

import { DB_GET_PARTS, DB_SET_PART } from '../actions/types';

export default function(state = {parts_id: 1, parts: []}, action) {

  switch (action.type) {

    case DB_GET_PARTS:
      return {parts_id: state.parts_id, parts: action.payload};
  
    case DB_SET_PART:
      return {...state, parts_id: action.payload};

    default:
      return state;
  }

}
