import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from "redux-thunk";
import { loadState, saveState } from './components/common/LocalStorage';
import throttle from 'lodash/throttle';
/*import logger from 'redux-logger';*/

import App from './components/App';
import reducers from './reducers';

const persistedState = loadState();

// always place logger at the end, ... the last middleware
const store = createStore(reducers, persistedState, applyMiddleware(reduxThunk/*, logger*/));

// save to local store every 100 seconds.
// If you don't do this, everytime you refresh a page, the redux store will be blank

store.subscribe(throttle(() => {
  saveState(store.getState());
},1000000));

ReactDOM.render (
  <Provider store={store}><App /></Provider>,
  document.querySelector('#root')
);
