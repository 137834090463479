import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, Skeleton } from 'antd';
import { dbSetCategory, dbGetChaptersByCategory, dbSetChapter, 
         setDefaultLessonPage } from '../../actions';
import { pageStyle, HEADER_HEIGHT, PARTS_HEIGHT, FOOTER_HEIGHT } from '../../config/paths';

// some of the antd css properties are overridden
import './categoriesPageCss.css'

const { Meta } = Card;

class Categories extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { isDataFetched: null};
  }

  componentDidMount() {
    this._isMounted = true;

    this.fetchInitialData();

  }

  //**********************************************************/
  fetchInitialData = async() => {

    if (this._isMounted)
      this.setState({isDataFetched: true});   
  }
 
  //**********************************************************/
  componentWillUnmount() {
    this._isMounted = false;
  }

  // onClick of the Card (category)
  onCategorySelect = async (categories_id) => {
    await this.props.dbSetCategory_action(categories_id);
    await this.props.dbGetChaptersByCategory_action(categories_id);   
    var chapters_id = this.getFirstChapter();
    await this.props.dbSetChapter_action(chapters_id);   
    await this.props.setDefaultLessonPage_action(1);   
    await this.props.history.push({
      pathname: '/Chapters',
      state: {auth: true}
    });  
   
  }

  getFirstChapter = () => {

    var firstChapter = this.props.dbChapters.chapters.reduce(function(res, obj) {
      return (obj.id < res.id) ? obj : res;
    });

    return firstChapter.chapters_id;
       
  }  

  renderCategoryImagesLayout = () => {

    const cardContainerStyle = {
//      width: 350,
      width: (this.props.dbParts.parts_id === 1) ? 300 : 350,
      height: 260,
      fontSize: 18,
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const cardStyle = {
      width:      250,
      height:     250,
      alignItems: 'center',
      justifyContent: 'center'
    };
    

      // 3 images for every row (each with span 8 out of 24)
      return this.props.dbCategories.categories.map((categoryRec) => {

        // a click on category, shoud take you to chapters
        // this should normally be done using <Link to="">
        // but doing that moves to the chapters route before finishing the async code in onCategorySelect
        // therefore history.push is used instead of <Link>
        return (
          <div style={cardContainerStyle} key={categoryRec.categories_id} >
              <Skeleton loading={!this.state.isDataFetched} active avatar paragraph={{ rows: 0 }}>
                <Card  hoverable style={cardStyle} cover={<img alt={"categoryRec.category"} src={categoryRec.imgPath} />}
                      onClick={async () => await this.onCategorySelect(categoryRec.categories_id)}>
                  <div style={{ display: 'flex', justifyContent: 'center' }} ><Meta title={categoryRec.category}/></div>
                  <p style={{ display: 'flex', justifyContent: 'center' }} >({categoryRec.numChapters} {(categoryRec.numChapters > 1) ? 'Chapters': 'Chapter'}) </p>
                </Card>
              </Skeleton>
          </div>
        );
      });
    
  }


  // component content
  renderContent() {

    // this is to force footer at the bottom in case of less content
    const categoryHeight = document.body.clientHeight - HEADER_HEIGHT - PARTS_HEIGHT - FOOTER_HEIGHT;

    const pageContainerStyle = {
      width: '100%',
      minHeight: categoryHeight,
      background: '#FFFFFF',
      margin: 0,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const categoriesContainerStyle = {
      //height: 560,
      width: '100%',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '18px',
      color: '#000000',
      background: '#FFFFFF',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      flexWrap: 'wrap'
    };

    return (
      <div style={pageContainerStyle}>
      <div style={pageStyle}>

        <div className="categories" style={categoriesContainerStyle}>
            {this.renderCategoryImagesLayout()}
        </div>

      </div>
      </div>

    );

  }

  render() {

    return (
      this.renderContent()
    );
  }

}

const mapStateToProps = (state) => {
  return {
    dbParts: state.dbParts,
    dbCategories: state.dbCategories,
    dbChapters: state.dbChapters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dbSetCategory_action: async (categories_id) => {
        await dispatch(dbSetCategory(categories_id))
    },
    dbGetChaptersByCategory_action: async (categories_id) => {
      await dispatch(dbGetChaptersByCategory(categories_id))
    },
    dbSetChapter_action: async (chapters_id) => {
      await dispatch(dbSetChapter(chapters_id))
    },
    setDefaultLessonPage_action: async(pageNo) => {
      await dispatch(setDefaultLessonPage(pageNo))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Categories));
