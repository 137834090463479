import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Radio } from 'antd';
import { dbSetPart, dbGetCategoriesByPart } from '../../actions';
import { pageStyle, PARTS_HEIGHT } from '../../config/paths';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class Parts extends React.Component {

  // get all parts from the reducer
  componentDidMount() {
  }

  // onChange of the radioButton (Part1 / Part2)
  onChange = async (e) => {
    await this.props.dbSetPart_action(e.target.value);
    await this.props.dbGetCategoriesByPart_action(e.target.value);
  }

  // layout of parts as radioButtons
  renderRadioButtonLayout() {

      if (this.props.dbParts.parts.length === 0) {
        return (<div></div>);
      } else {
        return this.props.dbParts.parts.map((partRec) => {
               return (<RadioButton key={partRec.parts_id} value={partRec.parts_id}>   {partRec.part}   </RadioButton>);
        });
      }
  }

  // component content
  renderContent() {

    const pageContainerStyle = {
      width: '100%',
      minHeight: PARTS_HEIGHT,
      background: '#FFFFFF',
      margin: 0,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const partsContainerStyle = {
      //height: 120,
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: 18,
      color: '#000000',
      background: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const partsContentContainerStyle = {
      height: 50,
      width: '100%',
      //flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const partsContentStyle = {
      buttonStyle: 'solid',
    };

    return (
        <div className="1x" style={pageContainerStyle}>
        <div className="2x" style={pageStyle}>

        <div className="parts" style={partsContainerStyle}>

          <div style={partsContentContainerStyle}>
            <RadioGroup style={partsContentStyle} onChange={this.onChange} value={this.props.dbParts.parts_id} buttonStyle="solid">
              {this.renderRadioButtonLayout()}
            </RadioGroup>
          </div>

        </div>

      </div>
      </div>

    );

  }

  render() {
    return (
      this.renderContent()
    );
  }

}

const mapStateToProps = (state) => {
  return {
    dbParts: state.dbParts
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dbSetPart_action: async (parts_id) => {
      await dispatch(dbSetPart(parts_id))
    },
    dbGetCategoriesByPart_action: async (parts_id) => {
      await dispatch(dbGetCategoriesByPart(parts_id))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Parts));
