import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, Skeleton } from 'antd';
import { dbGetVideos } from '../../actions';
import YouTube from 'react-youtube';
import { pageStyle } from '../../config/paths';

// some of the antd css properties are overridden
import './videosPageCss.css'

class Videos extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { isDataFetched: null};
  }

  componentDidMount() {
    this._isMounted = true;

    this.fetchInitialData();
  }

  //**********************************************************/
  fetchInitialData = async() => {
    await this.props.dbGetVideos_action();

    if (this._isMounted)
      this.setState({isDataFetched: true});   
  }
 
  //**********************************************************/
  componentWillUnmount() {
    this._isMounted = false;
  }

  renderVideosLayout = () => {

    const cardContainerStyle = {
      width: '100%',
      height: '100%',
      fontSize: '24px',
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    };
    
    const videoStyle = {
      width:      750,
      //height:     320,
      fontSize: '24px',
      alignItems: 'center',
      justifyContent: 'center'
    };

      // 3 images for every row (each with span 8 out of 24)
      return this.props.dbVideos.videos.map((videoRec) => {

        // a click on category, shoud take you to chapters
        // this should normally be done using <Link to="">
        // but doing that moves to the chapters route before finishing the async code in onCategorySelect
        // therefore history.push is used instead of <Link>
        return (
          <div style={cardContainerStyle} key={videoRec.videos_id} >
              <Card title={videoRec.description} bordered={false} >
                <div>
                  <YouTube videoId={videoRec.videoId} style={videoStyle}/>
                </div>
              </Card>                  
          </div>
        );
      });
    
  }


  // component content
  renderContent() {

    // this is to force footer at the bottom in case of less content
    let categoryHeight = (this.state.isDataFetched) ? 500*(this.props.dbVideos.videos.length) : 500;
    //categoryHeight += 80;

    const pageContainerStyle = {
      width: '100%',
      height: categoryHeight,
//      minHeight: categoryHeight,
      background: '#FFFFFF',
      margin: 0,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const categoriesContainerStyle = {
      height: '100%',
      width: '100%',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '24px',
      color: '#000000',
      background: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      flexWrap: 'wrap'
    };

    return (
      <div style={pageContainerStyle}>
      <div style={pageStyle}>

      <Skeleton loading={!this.state.isDataFetched} active avatar paragraph={{ rows: 0 }}>

        <div className="videos" style={categoriesContainerStyle}>
            {this.renderVideosLayout()}
        </div>

      </Skeleton>

      </div>
      </div>

    );

  }

  render() {

    return (
      this.renderContent()
    );
  }

}

const mapStateToProps = (state) => {
  return {
    dbVideos: state.dbVideos
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dbGetVideos_action: async() => {
      await dispatch(dbGetVideos())
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Videos));
