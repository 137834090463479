/*================================================================*/
/*=== action.payload is returned as 'res' from the backend ... ===*/
/*=== ... this is returned as an object ... ======================*/
/*=== ... so this is converted into an array of objects ... ======*/
/*=== ... so that libraries like lodash can use it effectively ===*/
/*================================================================*/

import { DB_GET_CUSTOMER, DB_SET_CUSTOMER_MSG, DB_RESET_CUSTOMER } from '../actions/types';

export default function(state = {customers_id: -1, token: '', errorMsg: '', expiresOn: '2021-09-25'}, action) {

  switch (action.type) {

    case DB_GET_CUSTOMER:
console.log('action.payload', action.payload);      
      return {...state, customers_id: action.payload.customers_id,
              token: action.payload.token, expiresOn: action.payload.expiresOn,
              errorMsg: '' } ;

    case DB_SET_CUSTOMER_MSG:
      return {...state, errorMsg: action.payload } ;

    case DB_RESET_CUSTOMER:
      return {...state, customers_id: -1, token: '', expiresOn: '2021-09-25', errorMsg: ''};
                        
    default:
      return state;
  }

}
