import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Breadcrumb, Icon, Input, Button } from 'antd';
import { ArrowLeftOutlined  } from '@ant-design/icons';
import { WEBPAGE_LANDING, WEBPAGE_CATEGORIES, WEBPAGE_CHAPTERS,
         WEBPAGE_LESSONS, WEBPAGE_VIDEOS } from '../../actions/types';
import { pageStyle } from '../../config/paths';

// some of the antd css properties are overridden
import './breadCrumbCss.css';

const Search = Input.Search;

class BreadCrumb extends Component {

  componentDidMount() {    
  }

  // get the name of the Category
  getCategoryName = () => {
    if (this.props.dbCategories.categories.length > 0) {
      const categoryRec = this.props.dbCategories.categories.filter(obj => { return obj.categories_id === this.props.dbCategories.categories_id});
      return categoryRec[0].category;
    }
    return " ";
  }

  // get the name of the Category
  getChapterName = () => {
    if (this.props.dbChapters.chapters.length > 0) {
      const chapterRec = this.props.dbChapters.chapters.filter(obj => { return obj.chapters_id === this.props.dbChapters.chapters_id});
      return chapterRec[0].chapter;
    }
    return " ";
  }

  // BreadCrumb info based on pageNum
  renderBreadCrumbLayout() {

    // this will animate help button only in trial period 
    let classNameAnim = "breadcrumbHelpDummy"

    if (this.props.webPage.webPages_id === WEBPAGE_LANDING) {
      return (<div></div>);
    } else if (this.props.webPage.webPages_id === WEBPAGE_CATEGORIES) {
      return(<div></div>);
    } else if (this.props.webPage.webPages_id === WEBPAGE_CHAPTERS) {
      return(
        <div style={{width: '100%', display:'flex', flexDirection: 'row'}}>

        <Breadcrumb style={{fontSize: 20, display: 'flex', flex: 16,  alignItems: 'center', width: '100%'}} separator=">">

          <Breadcrumb.Item>
            <Link to={{pathname: "/Categories", state: {auth: true} }}>
              <Icon type="home" style={{fontSize: 20}}/>
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item style={{fontSize: 20, fontWeight: '600px', color: 'rgba(0, 0, 0, 0.7)'}}>
            {this.getCategoryName()}
          </Breadcrumb.Item>

        </Breadcrumb>

        <div className={classNameAnim} style={{display: 'flex', fontSize: 20, flex: 6, justifyContent: 'flex-end'}} >
          <Link to={{pathname: "/Videos", state: {auth: true, fromRoute: '/Chapters'} }}>
            <Button type="text">Help</Button>
          </Link>
        </div>

      </div>

      );
    } else if (this.props.webPage.webPages_id === WEBPAGE_LESSONS) {

      return(
        <div style={{width: '100%', display:'flex', flexDirection: 'row'}}>
        <Breadcrumb style={{fontSize: 20, flex: 16}} separator=">">

          <Breadcrumb.Item>
            <Link to={{pathname: "/Categories", state: {auth: true} }}>
              <Icon type="home" style={{fontSize: 20}}/>
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item style={{fontSize: 20, fontWeight: '600px'}}>
            <Link to={{pathname: "/Chapters", state: {auth: true} }}>
              {this.getCategoryName()}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item style={{fontSize: 20, fontWeight: '600px'}}>
            {this.getChapterName()}
          </Breadcrumb.Item>

        </Breadcrumb>

          <div className={classNameAnim} style={{display: 'flex', fontSize: 20, flex: 6, justifyContent: 'flex-end'}} >
            <Link to={{pathname: "/Videos", state: {auth: true, fromRoute: '/Lessons'} }}>
              <Button type="text">Help</Button>
            </Link>
          </div>

        </div>

      )
    } else if (this.props.webPage.webPages_id === WEBPAGE_VIDEOS) {

      const route = (this.props.location.state.fromRoute === undefined) 
          ? '/Categories' : this.props.location.state.fromRoute;

      return(
        <Breadcrumb>

          <Breadcrumb.Item>
              <Link to={{pathname: route, state: {auth: true} }}>
                <div className="icons-list" >
                  <ArrowLeftOutlined  style={{fontSize: 20}}/>          
                </div>
              </Link>
          </Breadcrumb.Item>

        </Breadcrumb>
      );

    } else {
      return (<div></div>);
    }

  }

  // search layout based on page number
  searchLayout() {

    const breadCrumbInputStyle = {
      // vertically center the text in the container with the 3 lines
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'none'
    };

    if (this.props.webPage.webPages_id === WEBPAGE_LANDING) {
      return (<div></div>);
    } else if (this.props.webPage.webPages_id === WEBPAGE_CATEGORIES) {
      return(<div></div>);
    } else if (this.props.webPage.webPages_id === WEBPAGE_CHAPTERS) {
      return( <Search
                placeholder="input search text"
                style={breadCrumbInputStyle}
              />
      );
    } else if (this.props.webPage.webPages_id === WEBPAGE_LESSONS) {
      return(<div></div>);
    } else {
      return (<div></div>);
    }

  }


  renderContent() {

    const pageContainerStyle = {
      height: 40,
      width: '100%',
      backgroundColor: '#FFFFFF',
      margin: 0,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const breadCrumbStyle = {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#FFFFFF',
      paddingLeft: '1.5%'
    };

    const breadCrumbTextStyle = {
      fontSize: 24,
      fontWeight: '500',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    };

    return (
      <div style={pageContainerStyle}>
      <div id='xx' style={pageStyle}>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%'}} className="breadcrumb" >

        <div className="breadcrumb_content" style={breadCrumbStyle}>
          <div style={breadCrumbTextStyle}>
            {this.renderBreadCrumbLayout()}
          </div>
        </div>

      </div>
      </div>
      </div>

    );

  }

  render() {
    return (
      this.renderContent()
    );
  }

}

const mapStateToProps = (state) => {
  return {
    dbChapters: state.dbChapters,
    dbCategories: state.dbCategories,
    webPage: state.webPage
  };
};

export default connect(mapStateToProps)(withRouter(BreadCrumb));
