import { combineReducers } from 'redux';
import dbPartsReducer from './dbPartsReducer';
import dbCategoriesReducer from './dbCategoriesReducer';
import dbChaptersReducer from './dbChaptersReducer';
import dbLessonsReducer from './dbLessonsReducer';
import dbDefaultsReducer from './dbDefaultsReducer';
import webPageReducer from './webPageReducer';
import defaultLessonPageReducer from './defaultLessonPageReducer';
import loginAttemptReducer from './loginAttemptReducer';
import dbVideosReducer from './dbVideosReducer.js';
import dbCustomersReducer from './dbCustomersReducer.js';

export default combineReducers({
  webPage: webPageReducer,
  dbParts: dbPartsReducer,
  dbCategories: dbCategoriesReducer,
  dbChapters: dbChaptersReducer,
  dbLessons: dbLessonsReducer,
  dbDefaults: dbDefaultsReducer,
  defaultLessonPage: defaultLessonPageReducer,
  loginAttempt: loginAttemptReducer,
  dbVideos: dbVideosReducer,
  dbCustomers: dbCustomersReducer    
});
