let MAX_PAGE_WIDTH = 1366-35;
let NODE_PORT = '5000';
let PORT = '3000';
let IP = (process.env.NODE_ENV === 'production') ? 'https://www.vivaphysics.com' : 'http://localhost';
let coursePath = (process.env.NODE_ENV === 'production') ? 'content/courses/' : 'vivaphysics/courses/';

let animation_path = '';
let http_prefix = '';
let http_prefix_node = '';
let http_prefix_mojo = '';
const GOOGLE_CLIENT_ID = '722881002627-1jhom97m7go6rsik3ttj2ag805m4eh97.apps.googleusercontent.com';

animation_path = `${IP}/${coursePath}`;
http_prefix = `${IP}:${PORT}`;
http_prefix_node = (process.env.NODE_ENV === 'production') ? `${IP}` : `${IP}:${NODE_PORT}`;
http_prefix_mojo = (process.env.NODE_ENV === 'production') ? `${IP}` : `${IP}:${PORT}`;

function getBrowserWidth() {
  return Math.max(
/*
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth,
*/
    document.body.clientWidth
  );
}

/*
function getBrowserHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
}
*/

//let page_margin = (window.screen.availWidth-30 > MAX_PAGE_WIDTH) ? (window.screen.availWidth-30 - MAX_PAGE_WIDTH)/2 : 0;
let browser_width = getBrowserWidth();
browser_width = (browser_width > MAX_PAGE_WIDTH) ? MAX_PAGE_WIDTH : browser_width;
let page_margin = (browser_width > MAX_PAGE_WIDTH) ? (browser_width - MAX_PAGE_WIDTH)/2 : 0;
const pageStyle = {
  marginLeft: page_margin,
  marginRight: page_margin,
  width: browser_width
}

let HEADER_HEIGHT=30;
let BREADCRUMB_HEIGHT=40;
let PARTS_HEIGHT=60;
let FOOTER_HEIGHT=180;

let DEMO=false;
let UID=1;

module.exports = {
    lessonPhpPath: animation_path,
    GOOGLE_CLIENT_ID,
    http_prefix,
    http_prefix_node,
    http_prefix_mojo,
    pageStyle,
    HEADER_HEIGHT,
    BREADCRUMB_HEIGHT,
    PARTS_HEIGHT,
    FOOTER_HEIGHT,
    DEMO,
    UID
};
