/*================================================================*/
/*=== action.payload is returned as 'res' from the backend ... ===*/
/*=== ... this is returned as an object ... ======================*/
/*=== ... so this is converted into an array of objects ... ======*/
/*=== ... so that libraries like lodash can use it effectively ===*/
/*================================================================*/

import { DB_GET_LESSONS_BY_CHAPTER, DB_SET_LESSON } from '../actions/types';

export default function(state = {lessons_id: 0, lessons: []}, action) {

  switch (action.type) {
    case DB_GET_LESSONS_BY_CHAPTER:
      return {lessons_id: state.lessons.id, lessons: action.payload};
  
    case DB_SET_LESSON:
      return {...state, lessons_id: action.payload};

    default:
      return state;
  }

}
