import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from '../common/Header';
import ErrorBanner from '../common/ErrorBanner';
import Intro from '../landingPage/Intro';

import { setWebPage } from '../../actions';
import { WEBPAGE_LANDING } from '../../actions/types';

let moment = require('moment');

class LandingPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { isDataFetched: null};
  }

  // set web page
  componentDidMount() {
    this._isMounted = true;
    this.successSignIn();
    this.fetchInitialData();
 
  }

   //**********************************************************/
   fetchInitialData = async() => {
    await this.props.setWebPage_action(WEBPAGE_LANDING);
    if (this._isMounted)
      this.setState({isDataFetched: true});   
  }

  //**********************************************************/
  successSignIn = async () => {

    // Check for valid customer & against account expiry date for the customer
    let today = moment(new Date()).format("YYYY-MM-DD");

    let active = (typeof this.props.dbCustomers !== undefined) && (this.props.dbCustomers.expiresOn !== null) &&
                 (this.props.dbCustomers.expiresOn >= today);

    if ((this.props.dbCustomers.customers_id !== undefined) && (this.props.dbCustomers.customers_id > 0) && active) {     
      await this.props.history.push({
        pathname: '/Categories',
        state: {auth: true}
      });  
    }

  }
 
  componentWillUnmount() {
    this._isMounted = false;
  }

  renderContent() {

    const errorMsg = ((typeof this.props.dbCustomers === undefined) || (typeof this.props.dbCustomers.errorMsg === undefined)) ? '' : this.props.dbCustomers.errorMsg;

    return (
      <div>
        <Header/>
        <ErrorBanner message={errorMsg} />
        <Intro/>
      </div>
    );
  }

  render() {

    if (!this._isMounted) {
      return (
        <div></div>
      )
    }

    return (
      this.renderContent()
    );
  }

}

function mapStateToProps(state) {
  return {
    webPage: state.webPage,
    dbCustomers: state.dbCustomers,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setWebPage_action: async (webPages_id) => {
        await dispatch(setWebPage(webPages_id))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LandingPage));
