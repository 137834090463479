/*================================================================*/
/*=== Keep pageNo in the Lessons paginator =======================*/
/*=== user_id = 0, not logged in =================================*/
/*================================================================*/

import { SET_DEFAULT_LESSON_PAGE, GET_DEFAULT_LESSON_PAGE } from '../actions/types';

export default function (state = { pageNo: 1 }, action) {
  switch (action.type) {

    case SET_DEFAULT_LESSON_PAGE:
      return { ...state, pageNo: action.payload };

    case GET_DEFAULT_LESSON_PAGE:
      return { ...state};

    default:
      return state;
  }
}
