import axios from 'axios';
import { DB_GET_PARTS, DB_SET_PART,
         DB_GET_CATEGORIES_BY_PART, DB_SET_CATEGORY, DB_GET_CATEGORIES,
         DB_GET_CHAPTERS_BY_CATEGORY, DB_SET_CHAPTER,
         DB_GET_LESSONS_BY_CHAPTER, DB_SET_LESSON,
         SET_WEBPAGE, GET_WEBPAGE, DB_GET_DEFAULTS,
         GET_PERMISSION,
         SET_DEFAULT_LESSON_PAGE, GET_DEFAULT_LESSON_PAGE,
         INCR_LOGIN_ATTEMPT, GET_LOGIN_ATTEMPT, SET_LOGIN_ATTEMPT,
         DB_GET_VIDEOS,
         DB_GET_CUSTOMER, DB_SET_CUSTOMER_MSG} from './types';

// Get all parts
export const dbGetParts = () => async dispatch => {

  await axios.get('/db/getParts')
  .then((res) => {
    dispatch({ type: DB_GET_PARTS, payload: res.data });
  })
  .catch((err) => {
    console.log(err.response.data);
  })

};

// Set a Part
export const dbSetPart = (parts_id) => dispatch => {
  dispatch({ type: DB_SET_PART, payload: parts_id });
};

// Get all categories for a specified part
export const dbGetCategoriesByPart = (parts_id) => async dispatch => {

  await axios.get('/db/getCategoriesByPart/' + parts_id.toString())
  .then((res) => {
    dispatch({ type: DB_GET_CATEGORIES_BY_PART, payload: res.data });
  })
  .catch((err) => {
    console.log(err.response.data);
  })

};

// Get all categories 
export const dbGetCategories = () => async dispatch => {

  await axios.get('/db/getCategories')
  .then((res) => {
    dispatch({ type: DB_GET_CATEGORIES, payload: res.data });
  })
  .catch((err) => {
    console.log(err.response.data);
  })

};

// Set a category
export const dbSetCategory = (categories_id) => dispatch => {
  dispatch({ type: DB_SET_CATEGORY, payload: categories_id });
};

// Get all chapters for a category
export const dbGetChaptersByCategory = (categories_id) => async dispatch => {

  await axios.get('/db/getChaptersByCategory/' + categories_id.toString())
  .then((res) => {
    dispatch({ type: DB_GET_CHAPTERS_BY_CATEGORY, payload: res.data });
  })
  .catch((err) => {
    console.log(err.response.data);
  })

};

// Set a chapter
export const dbSetChapter = (chapters_id) => dispatch => {
  dispatch({ type: DB_SET_CHAPTER, payload: chapters_id });
};

// Get Lessons for a Chapter
export const dbGetLessonsByChapter = (chapters_id) => async dispatch => {

  await axios.get('/db/getLessonsByChapter/' + chapters_id.toString())
  .then((res) => {
    dispatch({ type: DB_GET_LESSONS_BY_CHAPTER, payload: res.data });
  })
  .catch((err) => {
    console.log(err.response.data);
  })

};

// Set Lessons
export const dbSetLesson = (lessons_id) => dispatch => {
  dispatch({ type: DB_SET_LESSON, payload: lessons_id });
};

// Set WebPage
export const setWebPage = (webPages_id) => dispatch => {
  dispatch({ type: SET_WEBPAGE, payload: webPages_id });
};

// Get WebPage
export const getWebPage = () => dispatch => {
  dispatch({ type: GET_WEBPAGE });
};

// the dispatch function is redux-thunk
export const dbGetDefaults = () => async dispatch => {

  await axios.get('/db/getDefaults')
  .then((res) => {
    dispatch({ type: DB_GET_DEFAULTS, payload: res.data });
  })
  .catch((err) => {
    console.log(err.response.data);
  })

};

export const getPermission = () => dispatch => {
  dispatch({ type: GET_PERMISSION });
};


export const setDefaultLessonPage = (pageNo) => dispatch => {
  dispatch({ type: SET_DEFAULT_LESSON_PAGE, payload: pageNo });
};

export const getDefaultLessonPage = () => dispatch => {
  dispatch({ type: GET_DEFAULT_LESSON_PAGE });
};

export const incrementLoginAttempt = () => dispatch => {
  dispatch({ type: INCR_LOGIN_ATTEMPT });
};

export const getLoginAttempt = () => dispatch => {
  dispatch({ type: GET_LOGIN_ATTEMPT });
};

export const setLoginAttempt = (attemptNo) => dispatch => {
  dispatch({ type: SET_LOGIN_ATTEMPT, payload: attemptNo });
};

// Get videos
export const dbGetVideos = () => async dispatch => {

  await axios.get('/db/getVideos')
  .then((res) => {
    dispatch({ type: DB_GET_VIDEOS, payload: res.data });
  })
  .catch((err) => {
    console.log(err.response.data);
  })

};


// the dispatch function is redux-thunk
export const dbGetCustomer =
  (customers_id, token) => async dispatch => {
    await axios.get(`/db/getCustomer/
          ${customers_id.toString().trim()}/${token.trim()}`)          
    .then ((res) => {
console.log('res',res.data[0]);      
      dispatch({ type: DB_GET_CUSTOMER, payload: res.data[0] });
    })
    .catch((error) => {
      console.log(error.response.data);
    });
  
};

// the dispatch function is redux-thunk
export const dbSetCustomerMsg =
  (errorMsg) => async dispatch => {
    dispatch({ type: DB_SET_CUSTOMER_MSG, payload: errorMsg });
  
};
